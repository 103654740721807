function generateYoutubeVideos() {
    let items = document.querySelectorAll("youtube-video");

    for (let i = 0; i < items.length; i++) {
        let item = items[i];
        let id = item.getAttribute("video-id");
        let img = item.getAttribute("image-path");

        let html = '<button type="button" id="playbutton" aria-label="Abspielen"><div class="icon"><svg fill="#004F6E" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg></div></button>';
        let poster = '';
        if (img != null && img.length > 0) {
            poster = '<div id="poster"><img src="' + img + '"></div>';
        } else {
            poster = '<div id="poster"><img src="https://i.ytimg.com/vi/' + id + '/maxresdefault.jpg"></div>';
        }
        let video = '<div class="wrapper"><iframe id="ytplayer" width="1600" height="900" src="//www.youtube-nocookie.com/embed/' + id + '?autoplay=1&amp;start=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe></div>';

        item.innerHTML = html + poster;

        item.addEventListener("click", function() {
            item.innerHTML = video;
        });
    }
}

if(document.querySelector("youtube-video")) {
    generateYoutubeVideos();
}

